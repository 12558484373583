:root {
  font-size: 16px;
  font-family: Helvetica;
  --container-padding: calc(8vw);
  --primary-color: rgb(96, 197, 231);
  --secondary-color: rgb(24, 44, 97);
  --box-shadow: 0 1px 10px rgb(0, 0, 0, .2), 0 -1px 10px rgb(0, 0, 0, .2);
  --hovered-box-shadow: 0 2px 10px rgb(0, 0, 0, .2), 0 -2px 10px rgb(0, 0, 0, .2);
}

html {
  scroll-behavior: smooth;
  color: var(--secondary-color);
  box-sizing: border-box;
}

h2 {
  text-align: center;
  color: var(--heading-color);
  font-size: 3rem;
  margin-bottom: 3rem;
  margin-top: 0rem;
}

:focus {
  outline: none;
}

[id*="-location"] {
  position: relative;
  bottom: 30px;
}

.container {
  padding: 0 var(--container-padding);
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.error-banner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 0 2rem;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  background-color: rgb(255, 91, 91);
  color: white;
  font-size: 1.25rem;
}

.error-banner-text {
  margin-right: 3rem;
}

.error-banner-button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}

.error-banner-icon {
  font-size: 2rem;
  transition: opacity .1s;
}

.error-banner-icon:hover {
  cursor: pointer;
  opacity: .7;
}

#navbar {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  color: white;
  height: 2.5rem;
}

#nav-left {
  font-size: 24px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#nav-left >* {
  margin-right: 10px;
}

#nav-right {
  font-size: 1.5rem;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#nav-right >* {
  margin-left: 7vw;
}

.nav-item {
  color: inherit;
  text-decoration: none;
  transition: opacity .1s;
}

.nav-item:hover {
  opacity: .7;
}

#nav-left > div {
  font-weight: bold;
}

@keyframes icon-squish {
  50%       { transform: scale(.85, 1.15) }
  100% { transform: scale(1, 1) }
}

#nav-left:hover > #logo {
  animation: icon-squish 0.5s ease-in-out 0s infinite forwards;
}

#nav-menu-toggle {
  display: none;
  position: relative;
  z-index: 2;
}

.nav-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  padding: 4px 7px;
  font-size: 2.5rem;
}

.nav-button-fixed {
  position: fixed;
  top: 43px;
  right: var(--container-padding);
}

#nav-menu {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .8);
  backdrop-filter: blur(5px);
  padding: 40px var(--container-padding);
  display: flex;
  flex-direction: column;
}

#nav-menu > .nav-item {
  font-size: 3rem;
  margin-bottom: 4rem;
  width: 80%;
}

#nav-menu > .nav-item:hover {
  color: var(--primary-color);
}

@keyframes slide-fade-in {
  0%   { transform: translateY(100px); opacity: 0; }
  100% { transform: translateY(0px); opacity: 1; }
}

#about {
  background: linear-gradient(var(--primary-color), var(--primary-color), var(--primary-color), white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 75px var(--container-padding) 250px;
  color: white;
}

#about-header {
  text-align: center;
  color: white;
  font-size: 4rem;
  margin-bottom: 30px;
  animation: slide-fade-in 1s ease-out 0s 1;
}

#about-subheader {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 0;
  animation: slide-fade-in 1s ease-out 0s 1;
}

#login-portal {
  text-align: center;
  margin-bottom: 16rem;
  padding: 0 var(--container-padding);
}

#login-header {
  font-size: 3rem;
  margin-top: 4rem;
}

#login-subheader {
  font-size: 1.5rem;
  width: 70vw;
  margin: 0 auto;
}

#login-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70vw;
  margin: 3rem auto;
}

#password-label {
  margin: 0;
  font-size: 2rem;
}

#password-field {
  font-size: 1.5rem;
  margin-left: 2rem;
  padding: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
}

#login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  background: var(--secondary-color);
  border: none;
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
  transition: transform .15s, box-shadow .15s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

#login-button:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 1px 10px rgb(0, 0, 0, .1), 0 -1px 10px rgb(0, 0, 0, .1);
}

#login-button:active {
  transform: scale(1);
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.login-icon {
  margin-right: 1rem;
}

#photo-slideshow {
  --heading-color: var(--secondary-color);
}

#photo-slideshow h2 {
  margin-top: 6rem;
}

#slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
}

#slideshow-photos {
  position: relative;
  width: 45vw;
  height: 45vw;
  margin: 0 3vw;
}

[id*=slideshow-photo-] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 92%;
  height: 92%;
  border-radius: 50%;
  border: 5px solid var(--primary-color);
  object-fit: cover;
  box-shadow: var(--box-shadow);
  transition: opacity .5s;
}

.slideshow-button {
  background-color: transparent;
  border: none;
  font-size: 5vw;
  color: inherit;
}

.slideshow-button-icon {
  border-radius: 100%;
  padding: 0px;
  box-shadow: var(--box-shadow);
  transform: scale(1);
  transition: transform .15s, box-shadow .15s;
}

.slideshow-button-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: var(--hovered-box-shadow);
}

.slideshow-button-icon:active {
  transform: scale(1);
  box-shadow: var(--box-shadow);
}

#slideshow-captions {
  position: relative;
  width: 60vw;
  height: auto;
  margin: 3rem auto 12rem;
}

[id*='slideshow-caption-'] {
  font-size: 1.25rem;
  text-align: center;
  line-height: 2rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: min-content;
  transition: opacity .5s;
}

[id*=hidden-caption-] {
  opacity: 0;
  display: none;
  line-height: 2rem;
  margin-bottom: 10rem;
}

#quote-left, #quote-right {
  position: absolute;
  font-size: 4rem;
  opacity: .2;
}

#quote-left {
  left: max(-14vw, -7rem);
  top: -2rem;
}

#quote-right {
  right: max(-14vw, -7rem);
  bottom: -2.5rem;
}

#public-memories, #private-memories {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

#public-memories {
  --color: var(--primary-color);
  --heading-color: white;
  --memory-background: linear-gradient(to bottom right, white, rgb(233, 233, 233));
  --button-color: var(--primary-color);
  --button-text-color: white;
  background-color: var(--primary-color);
  color: var(--color);
}

#private-memories {
  --color: white;
  --heading-color: var(--primary-color);
  --memory-background: linear-gradient(to bottom right, var(--primary-color), rgb(58, 184, 226));
  --button-color: white;
  --button-text-color: var(--primary-color);
  background-color: white;
  color: var(--color);
}

.memory {
  font-size: 1.25rem;
  line-height: 2rem;
  background: var(--memory-background);
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.memory-text {
  margin: 0;
}

.memory-input {
  box-sizing: border-box;
  width: 100%;
  height: 8rem;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--color);
  border-color: var(--color);
  background-color: var(--memory-background);
  border-radius: 5px;
  padding: 1rem;
  resize: none;
}

.memory-buttons {
  display: flex;
  justify-content: space-between;
}

.memory-buttons-left, .memory-buttons-right {
  width: 50%;
  display: flex;
}

.memory-buttons-right .memory-button-icon {
  margin-right: 0;
}

.memory-buttons-left {
  justify-content: flex-start;
}

.memory-buttons-right {
  justify-content: flex-end;
  align-items: flex-end;
}

.memory-buttons-right .slideshow-button {
  font-size: 3rem;
  margin-right: 0;
  margin-left: 1.5rem;
  padding: 0;
  background-color: transparent;
}

.memory-buttons-right .slideshow-button-icon {
  border-radius: 100%;
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.memory-buttons-right .slideshow-button-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 1px 10px rgb(0, 0, 0, .1), 0 -1px 10px rgb(0, 0, 0, .1);
}

.memory-buttons-right .slideshow-button-icon:active {
  transform: scale(1);
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.memory-button {
  font-size: inherit;
  text-align: center;
  padding: .75rem;
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  border-radius: 5px;
  color: var(--button-text-color);
  background-color: var(--button-color);
  border: none;
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
  transition: transform .15s, box-shadow .15s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

.memory-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 1px 10px rgb(0, 0, 0, .1), 0 -1px 10px rgb(0, 0, 0, .1);
}

.memory-button:active {
  transform: scale(1);
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.memory-button-icon {
  margin-right: .5rem;
}

.new-memory-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
}

.new-memory-button {
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
  border-radius: 5px;
  color: var(--color);
  background: var(--memory-background);
  border: none;
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
  transition: transform .15s, box-shadow .15s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}


.new-memory-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 1px 10px rgb(0, 0, 0, .1), 0 -1px 10px rgb(0, 0, 0, .1);
}

.new-memory-button:active {
  transform: scale(1);
  box-shadow: 0 1px 5px rgb(0, 0, 0, .1), 0 -1px 5px rgb(0, 0, 0, .1);
}

.new-memory-icon {
  margin-right: 1rem;
}

.delete-confirmation-text {
  margin-bottom: 0;
}

@media screen and (max-width: 904px) {
  #login-button {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 850px) {
  :root {
    font-size: 2.2vw;
  }

  #about {
    padding-top: calc(5vw + 35px);
  }

  #nav-menu-toggle {
    display: inline-block;
  }

  #nav-left {
    width: 80%;
  }
  
  #nav-right {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  #nav-left {
    font-size: 4.8vw;
  }

  [id*=slideshow-photo-] {
    border: 3px solid var(--primary-color);
  }
}